input.input-text-black::placeholder {
  color: black;
  font-weight: 500;
}
/* phone */
@media (min-width: 320px) {
  .jumbotron_size {
    height: 50vh;
    max-width: 1440px;
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 420px) {
  .ad-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.7em;
    margin-top: 0.7em;
  }
  .ad-card:not(:nth-child(2)) {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.7em;
    margin-top: 0.7em;
  }
}
/* small  */
@media (min-width: 640px) {
  .jumbotron_size {
    height: 30vh;
    max-width: 1440px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ad-card:nth-child(2) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1em;
  }
  .ad-card:not(:nth-child(2)) {
    width: 48%;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  .ad-card:nth-child(3) {
    margin-left: 0;
  }
  .ad-card:nth-child(4) {
    margin-right: 0;
  }
}
/* medium  */
@media (min-width: 768px) {
  .jumbotron_size {
    height: 40vh;
    max-width: 1440px;
  }
}

/* large  */

@media (min-width: 1024px) {
  .jumbotron_size {
    height: 80vh;
    max-width: 1440px;
  }
}
@media (min-width: 1024px) {
  .ad-card {
    width: 25%;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
  .ad-card:last-child {
    margin-right: 0;
  }
}
